.member {
    .atc-privateMessageContainer {
        position: absolute;
    }
}

.non-member {
    #ATC-DM-Template {
        display: none !important;
    }
}

// Cookie Consent Feature
.cookie {
    // Semi-transparent backdrop that appears behind the cookie banner
    &-banner-backdrop {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 999;
        opacity: 0;
        transition: opacity 0.3s ease;

        &.show {
            display: block;
            opacity: 1;
        }
    }

    // Main cookie consent banner that slides up from bottom
    &-banner {
        position: fixed;
        bottom: -100%;
        right: 20px;
        background-color: white;
        border: 1px solid #d3d3d3;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        max-width: 600px;
        width: calc(100% - 40px);
        z-index: 1000;
        transition: bottom 0.8s ease-in-out;

        &.show {
            bottom: 20px;
        }

        &.slide-out {
            bottom: -100%;
        }

        &-content {
            h4 {
                font-weight: bold;
                color: #000;
                text-align: left;
            }

            p {
                color: #666;
                text-align: left;
            }

            .privacy-link {
                color: #007bff;
            }

            .button-group {
                display: inline-flex;
                gap: .5em;
            }
        }
    }

    // Fixed preferences icon that appears in the bottom-left corner
    &-icon {
        position: fixed;
        bottom: 20px;
        left: 20px;
        z-index: 1000;

        &-button {
            background: white;
            border: 1px solid #ddd;
            border-radius: 50%;
            padding: 10px;
            cursor: pointer;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        }
    }

    // Styles for the cookie preferences modal
    &-modal {
        &-description {
            color: #666;
            margin-bottom: 0;
        }

        &-buttons {
            width: 100px;
            display: flex;
            flex-direction: column;
        }

        &-wide-button {
            width: 110px;
        }

        &-custom-width {
            max-width: 40%;
        }
    }
}

#RoomHeader {
    margin: 0 auto;

    .row {
        .col-md-8 {
            padding-left: 0;
        }
    }

    #profileTotalPosts {
        width: 100%;
    }

    #atcProfileUserInfo {
        max-width: unset;
        min-width: unset;
        padding: 0;
        position: relative;
        width: 100%;
        /*margin-bottom: 55px;*/

        .atc-avatar-wrapper {
            float: left;
            margin: 12px 15px 0 15px;
            position: relative;
            width: 55px;

            i.fa.onlineStatus {
                bottom: 3%;
                display: block;
                position: absolute;
                right: 3%;
                left: unset;
                top: unset;
            }
        }

        .img-profile {
            border-radius: 50%;
            float: left;
            height: 55px;
            margin: 0;
            width: 55px;
        }

        .profile-user-block {
            display: block;
            float: left;
            margin-left: 1%;
            margin-top: 12px;
            text-align: left;
            width: 60%;

            h3 {
                color: #6299ca;
                font-size: 16px;
                line-height: 1.1em;
                margin: 0;
            }

            .member-since {
                font-size: 11px;
            }
        }
    }

    #atcProfileBio {
        border-left: 1px solid #dedede;
        float: left;
        line-height: 1.7em;
        min-height: 150px;
        width: 100%;
        padding: 10px;
        .row {
            position: absolute;
            bottom: 0;
            white-space: nowrap;
        }
    }
}

.tab-filter {
    clear: both;
    height: auto;
    line-height: normal;
    list-style: none;
    margin: 0;
    padding: 0 0 0 20px;
    background-color: white;
    border-top: 1px solid #dedede;
    white-space: nowrap;
    overflow-x: auto;

    li {
        display: inline-block;
        font-weight: bold;
        line-height: 3.0em;
        margin-left: 13px;
        text-align: center;
        width: auto;
        padding: 0 28px 0 28px;

        &.selected {
            border-bottom: 4px solid #03a678;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

#add-filter, #my-filters {
    padding-top: 30px;
}

#add-filter {
    .form-group:first-child {

    }
    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0,0,0,.1);
    }
}

.my-fillter-is-hide {
    display: none;
}

.select2-container .select2-search--inline:only-child { float: none; }

.trading-room {
    @media (min-width: 992px) {
        #mobilePageReplyHdr {
            display: none !important;
        }
    }
}

.byline {
    font-size: 12pt;
    line-height: 1em;
    a {
        margin: 5px;
        font-size: 20px;
        .fa-facebook-square {
            color: #3b5599;
        }
        .fa-twitter-square {
            color: #00acee;
        }
        .fa-rss-square {
            color: #ee802f;
        }
    }
}

.video-content {
    video {
        max-width: 100%;
    }
}

a.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.divAddVideoTagForm {
    display: none;
    margin: 5px auto;
    max-width: 500px;
    min-width: 200px;
    padding: 10px;
    position: relative;
    width: 40%;
    background-color: #465e75;
}

.tr-stocktradeBlock {
    .tr-stocksymbol {
        padding: 5px;
        background-color: #465e75;
        color: white;
    }
}

#progressbar-container {
    border: 1px solid #dddddd;
    margin-bottom: 1em;
    background-color: #f5f5f5;
    padding: 10px;
    position: relative;
    z-index: 1042;
}

.progressbar-container {
    .progressbar-item-container {
        max-width: 500px;
        margin: 0 auto;
    }
    .progressbar-steps {
        display: flex;
        justify-content: space-between;
        font-size: 0.75em;
        padding: 0.3em 1.5em;
    }
}

.progressbar-container {
    .progressbar-background {
        background-color: #ccc;
        border-radius: 8px;
        height: 4px;
    }
    .progressbar-progress {
        &.progressbar-step-1 {
            width: 26%;
        }
        &.progressbar-step-2 {
            width: 58%;
        }
        &.progressbar-step-3 {
            width: 100%;
        }
        background-color: black;
        border-radius: 8px;
        height: 4px;
    }
}

.portfolioTable {
    td {
        form {
            display: inline;
        }
        a.adminPortfolioAction.btn, button.btn {
            padding: 0;
        }
        a.adminPortfolioAction:not(last-of-type) {
            margin-right: 10px;
        }
    }
}

@media (max-width: 991px) {
    #mobilePageReplyHdr {
        height: 60px;
        line-height: 60px;
        background-color: #35495D;
        color: white;
        font-size: 30px;
        text-align: center;
        width: 100%;
        z-index: 101;
        position: fixed;
        top: 0;
        .mobilePageHdrReplaceLogo {
            height: 50px;
            margin: 0 auto;
            width: 50%;
        }
        .btnMobileGoBack {
            color: #fff;
            display: block;
            float: left;
            height: 50px;
            width: 60px;
        }
        a.btnMobileGoBack:hover  {
            background-color: unset !important;
            color: unset !important;
            text-decoration: unset !important;
            cursor: pointer;
        }
    }
    /*
    #RightReplies-Header {
        display: none;
    }
     */
    .select2-container {
        max-width: 280px;
    }
    #RoomHeader {
        margin-top: 80px;
    }
}

@media (max-width: 991px) and (min-width: 769px) {
    .showOnlyOnPhone {
        display: block !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    body.member .contentArea form fieldset label.hideOnPhone {
        display: block !important;
    }
}

@media (max-width: 768px) {
    #cookie-banner {
        right: 0;
        left: 0;
        margin: 0 auto;
        bottom: -100%;
    }

    #cookie-banner.show {
        bottom: 0;
        border-radius: 8px 8px 0 0;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}
